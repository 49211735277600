import getConfig from 'next/config'
import axios from 'axios'

const {
  KRONOS_BASE_URL,
  HOST
} = getConfig().publicRuntimeConfig

const updateToken = (client, token) => {
  client.options.headers.authorization = token
}

const kronosRest = async (path, options) => {
  const { data } = await axios(`/api${path}`, options)
  return data
}

const userAuthRest = async (path, options = { mode: 'cors' }) => {
  const { data } = await axios(`/api${path}`, options)
  return data
}

const productsRest = async (path, options) => {
  const { data } = await axios(`/api${path}`, options)
  return data
}

const quotationsRest = async (path, options) => {
  const { data } = await axios(`/api${path}`, options)
  return data
}

const ordersRest = async (path, options) => {
  const { data } = await axios(`/api${path}`, options)
  return data;
}

const localRest = async (path, options) => {
  const { data } = await axios(`/api${path}`, options)
  return data
}

export { updateToken, kronosRest, userAuthRest, productsRest, quotationsRest, ordersRest, localRest }
